<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <span class="text-white title-login">
        Sở Lao Động - Thương Binh và Xã Hội Tỉnh {{ province }}
      </span>
      <span class="text-white title-login-small">
        Quản lý an toàn lao động
      </span>

      <!-- Brand logo-->
      <div @click="goToHome">
        <div class="brand-logo logo-home">
          <b-img
            class="w-100"
            :src="sideImglogo"
          />
        </div>
        <!-- <vuexy-logo
          class="brand-logo logo-home "
        /> -->
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex theme-login w-100"
        :style="{background: `url(${sideImg})`}"
      >
        <!-- <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
            class="cover-img"
          />
        </div> -->
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h3"
          >
            Kích hoạt tài khoản
          </b-card-title>
          <b-card-text class="mb-2">
            Vui lòng kích hoạt tài khoản trước khi sử dụng!
          </b-card-text>

          <b-card-text class="text-center mt-2">
            <b-button
              variant="primary"
              class="w-100"
              @click="handleActiveAccount"
            >
              Kích hoạt tài khoản
            </b-button>
          </b-card-text>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import $ from 'jquery'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import ButtonComponent from '@/components/button/ButtonComponent.vue'
import businessMenu from '@/navigation/vertical/business-menu'
import departmentMenu from '@/navigation/vertical/department-menu'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import storeDefault from '@/home/store'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ButtonComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/IMG3.png'),
      roleUser: null,
      cIp: '',
      sideImglogo: storeDefault.sideImglogo,
      province: storeDefault.province,
      // validation rules
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/IMG3.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    handleActiveAccount() {
      const url = new URL(window.location.href)
      const payload = {
        code: url.searchParams.get('code'),
        userId: url.searchParams.get('userId'),
      }
      this.$showAllPageLoading()
      axiosApiInstance.post('/Login/confirm-email', payload).then(response => {
        this.$hideAllPageLoading()
        this.$router.push({ name: 'active-account-status', params: { type: 'thanh-cong' } })
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$router.push({ name: 'active-account-status', params: { type: 'khong-thanh-cong' } })
      })
    },
    goToHome() {
      this.$router.push({ name: 'home', params: { check: true } })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.logo-home {
  cursor: pointer;
}
.theme-login {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.title-login {
  position: absolute;
  z-index: 1;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  top: 3rem;
  left: 5rem;
  width: 40%;
}

.title-login-small {
  position: absolute;
  z-index: 1;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  top: 9.5rem;
  left: 5rem;
}

@media (max-width: 1300px) {
  .title-login {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .title-login {
    display: none;
  }
  .title-login-small {
    display: none;
  }
}
</style>
